@font-face {
    font-family: 'Proxima Nova';
    src: url("/assets/fonts/ProximaNova_Thin.otf") format("opentype");
    font-weight: 100;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("/assets/fonts/ProximaNova_Light.otf") format("opentype");
    font-weight: 200 300;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("/assets/fonts/ProximaNova_Reg.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Proxima Nova';
    src: 
        url("/assets/fonts/ProximaNova_Bold.otf") format("opentype");
    font-weight: bold;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Proxima Nova';
    src: url("/assets/fonts/ProximaNova_Sbold.otf") format("opentype");
    font-weight: 500 600;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Sharp Sans';
    src: 
        url("/assets/fonts/SharpSans-Extrabold.woff2") format("woff2"),
        url("/assets/fonts/SharpSans-Extrabold.woff") format("woff")
        url("/assets/fonts/SharpSans-Extrabold.otf") format("opentype");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Sharp Sans';
    src: 
        url("/assets/fonts/SharpSans-Bold.woff2") format("woff2"), 
        url("/assets/fonts/SharpSans-Bold.woff") format("woff")
        url("/assets/fonts/SharpSans-Bold.otf") format("opentype");
    font-weight: bold;
    font-display: swap;
    font-feature-settings: 'ss01';
}

@font-face {
    font-family: 'Sharp Sans';
    font-weight: 500 600;
    src:
        url("/assets/fonts/SharpSans-Semibold.woff2") format("woff2"),
        url("/assets/fonts/SharpSans-Semibold.woff") format("woff"),
        url("/assets/fonts/SharpSans-Semibold.otf") format("opentype");
}

@layer font {
    :root {
        --text-nm: 18.66px;
        --text-lg: 24px;
    }
}

