@layer color {
    :root {
      --color-primary: 35 45 97;
      --color-secondary: 244 123 32;

      --color-primary-navy: 35 45 97;
      --color-primary-blue: 38 164 221;
      --color-primary-pink: 225 19 111;
      
      --color-secondary-orange: 244 123 32;
      --color-secondary-teal: 103 200 202;
      --color-secondary-yellow: 255 194 51;

      --color-navy-darkest: 20 25 56;
      --color-navy-dark: 18 27 80;
      --color-navy-base: 35 44 97;
      --color-navy-light: 121 127 159;
      --color-navy-lighter: 166 170 191;
      --color-navy-lightest: 235 238 255;

      --color-blue-darkest: 3 107 181;
      --color-blue-dark: 26 137 201;
      --color-blue-base: 38 163 221;
      --color-blue-light: 112 195 233;
      --color-blue-lighter: 211 236 248;
      --color-blue-lightest: 242 249 252;

      --color-pink-darkest: 189 10 80;
      --color-pink-dark: 217 12 91;
      --color-pink-base: 225 18 110;
      --color-pink-light: 248 160 199;
      --color-pink-lighter: 251 208 227;
      --color-pink-lightest: 255 239 246;

      --color-orange-darkest: 209 73 0;
      --color-orange-dark: 224 88 18;
      --color-orange-base: 244 122 32;
      --color-orange-light: 248 168 109;
      --color-orange-lighter: 250 197 158;
      --color-orange-lightest: 253 226 206;

      --color-teal-darkest: 27 131 133;
      --color-teal-dark: 69 168 170;
      --color-teal-base: 102 200 202;
      --color-teal-light: 160 221 222;
      --color-teal-lighter: 198 235 235;
      --color-teal-lightest: 217 241 242;

      --color-yellow-darkest: 239 155 22;
      --color-yellow-dark: 246 174 35;
      --color-yellow-base: 254 194 49;
      --color-yellow-light: 254 210 103;
      --color-yellow-lighter: 255 225 153;
      --color-yellow-lightest: 255 240 204;

      --color-black: 20 26 31;
      --color-gray-darker: 61 79 92;
      --color-gray-dark: 82 105 122;
      --color-gray: 194 206 214;
      --color-gray-light: 224 230 235;
      --color-gray-lighter: 240 243 245;
      --color-gray-lightest: 249 250 251;
      --color-white: 255 255 255;
    }
  }