:root {
    --arrow-up-icon: url("/assets/feather/arrow-up.svg");
    --circle-icon: url("/assets/feather/circle.svg");
    --flag-icon: url("/assets/feather/flag.svg");
    --chevron-down-icon: url("/assets/feather/chevron-down.svg");
    --edit-icon: url("/assets/feather/edit.svg");
    --trello-icon: url("/assets/feather/trello.svg");
    --message-circle-icon: url("/assets/feather/message-circle.svg");
    --log-out-icon: url("/assets/feather/log-out.svg");
    --search-icon: url("/assets/feather/search.svg");
}

[data-icon]::after {
    mask-repeat: no-repeat;
    mask-position: center;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    content: var(--tw-content);
}

[data-icon="flag"]::after {
    mask-image: var(--flag-icon);
}

[class$="-icon"] {
    display: flex;
}

[class$="-icon"]::after {
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    content: "";
}

[class$="-icon-before"]::before {
    mask-repeat: no-repeat;
    mask-position: center;
    mask-size: contain;
    width: 1.5rem;
    height: 1.5rem;
    display: block;
    content: "";
}

.arrow-up-icon::after {
    mask-image: var(--arrow-up-icon);
    width: 1.5rem;
    height: 1.5rem;
    background-color: white;
}

.chevron-down-icon::after {
    mask-image: var(--chevron-down-icon);
}

.edit-icon::after, .edit-icon-before::before {
    mask-image: var(--edit-icon);
}

.trello-icon::after, .trello-icon-before::before {
    mask-image: var(--trello-icon);
}

.message-circle-icon::after, .message-circle-icon-before::before {
    mask-image: var(--message-circle-icon);
}

.log-out-icon::after, .log-out-icon-before::before {
    mask-image: var(--log-out-icon);
}

.search-icon::after, .search-icon-before::before {
    mask-image: var(--search-icon);
}