.widget-tabs {
  @apply w-full rounded-xl bg-white p-2 flex flex-col gap-1
}
.widget-tabs [role=tablist] {
  @apply border-0
}
.widget-tabs .widget-tabs-title {
  /* @apply m-0 rounded-xl p-4 flex-grow shadow font-medium text-lg leading-5 ring-white/60 ring-offset-2 ring-offset-navy-600/60 */
  @apply flex gap-2 w-full justify-between rounded-lg bg-blue-300 px-4 py-2 font-medium m-0 cursor-pointer border-2 border-gray-300
}
.widget-tabs .widget-tabs-title.active {
  @apply text-navy-600 shadow border-navy-600
}
.widget-tabs .widget-tabs-title:focus {
  /* @apply outline-none ring-2 */
  @apply outline-none
}
.widget-tabs .widget-tabs-title:hover {
  /* @apply shadow-inner */
  @apply border-navy-300
}
.widget-tabs .widget-tabs-title.active:hover {
  /* @apply shadow-inner */
  @apply border-navy-600
}
.widget-tabs .widget-tabs-container {
  @apply rounded-xl bg-white p-3 ring-white/60 ring-offset-2 ring-offset-blue-400
}
.widget-tabs .widget-tabs-container:focus {
  @apply outline-none ring-2
}