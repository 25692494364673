.widget-status-message {
    @apply border p-4 pt-2 gap-4 bg-white rounded my-3 shadow flex
}

.widget-status-message > :last-child {
    @apply overflow-x-auto
}

.widget-status-message.note {
    @apply border-blue-600
}

.widget-status-message.important {
    @apply border-orange-800
}

.widget-status-message.caution {
    @apply border-pink-800
}

.widget-status-message.warning {
    @apply border-pink-800
}

.widget-status-message .widget-status-message-title {
    @apply mb-2 font-bold font-display
}

.widget-status-message.note .widget-status-message-title {
    @apply  text-blue-600
}

.widget-status-message.important .widget-status-message-title {
    @apply  text-orange-800
}

.widget-status-message.caution .widget-status-message-title {
    @apply  text-pink-800
}

.widget-status-message.warning .widget-status-message-title {
    @apply  text-pink-800
}

.widget-status-message .widget-status-message-container {
    @apply flex
}

.widget-status-message.note > .icon:before {
    content: url("/assets/custom/paper-airplane.svg");
}

.widget-status-message.note > .icon {
    @apply pt-2
}