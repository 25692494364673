
body .widget-faq {
  @apply max-w-screen-md rounded-xl bg-white p-2 flex flex-col gap-1
}

.widget-faq-q {
  @apply flex gap-2 w-full justify-between rounded-lg bg-blue-300 px-4 py-2 font-medium m-0
}

body .ck h3.widget-faq-q {
  @apply m-0 font-medium
}

.widget-faq-q:hover {
  @apply bg-navy-300
}

.widget-faq-q:focus {
  @apply outline-none
}

.widget-faq-q:focus-visible {
  @apply ring ring-blue-500/75
}

.widget-faq-a {
  @apply px-4 pb-2 pt-1
}